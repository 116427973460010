import React from 'react';
import cx from 'classnames'
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ReactTyped } from 'react-typed';
import styles from './Hero.module.scss';

const Hero: React.FC = () => {
    const theme = useTheme();

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const isSmallScreen = useMediaQuery('(max-width:420px)');


    return (
        <Grid container className={cx(styles.heroContainer)}>
            <Typography
                className={styles.heroTitle}
            >
                <Typography
                    className={styles.heroTitle}
                >
                    Pioneering Purposeful Digital Products
                </Typography>
                to Elevate Your {' '}
                {isSmallScreen && <br />}
                <span
                    className={styles.heroHighlightText}
                    style={{
                        background: `linear-gradient(180deg, transparent 90%, ${alpha(theme.palette.primary.main, 0.3)} 0%)`,
                        whiteSpace: 'nowrap',
                    }}
                >
                    <ReactTyped
                        strings={['Business', 'Startup', 'Brand']}
                        typeSpeed={100}
                        loop={true}
                    />
                </span>
            </Typography>

            <div className={styles.heroSubTextContainer} >
                <Typography className={styles.heroSubText} >
                    Collaborate with us to create bespoke digital products tailored to your needs.
                </Typography>
            </div>
            <div className={styles.heroButtonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth={!isMd}
                    style={{ borderRadius: 12 }}
                    href='#contact'
                >
                    Start now
                </Button>
                <Box
                    component={Button}
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth={!isMd}
                    style={{ borderRadius: 12 }}
                     href='#services'
                >
                    Learn more
                </Box>
            </div>
        </Grid>
    );
};

export default Hero;
