import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Introduction</strong>
        <br />
        This Privacy Policy governs how PinakArc ("we" or "us") collects, uses, maintains, and discloses information collected from users ("you" or "users") of the pinakarc.com website ("Website"). This policy applies to the Website and all products and services offered by PinakArc.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Consent</strong>
        <br />
        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Information we collect</strong>
        <br />
        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
        <br />
        If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you choose to provide.
        <br />
        When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>How we use your information</strong>
        <br />
        We use the information we collect in various ways, including to:
        <ul>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Log Files</strong>
        <br />
        PinakArc follows a standard procedure of using log files. These files log visitors when they visit websites. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Advertising Partners Privacy Policies</strong>
        <br />
        You may consult this list to find the Privacy Policy for each of the advertising partners of PinakArc.
        <br />
        Third-party ad servers or ad networks may use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on PinakArc, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Third Party Privacy Policies</strong>
        <br />
        PinakArc’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
        <br />
        You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>CCPA Privacy Rights (Do Not Sell My Personal Information)</strong>
        <br />
        Under the CCPA, among other rights, California consumers have the right to:
        <ul>
          <li>Request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
          <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
          <li>Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data.</li>
        </ul>
        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Children’s Information</strong>
        <br />
        Protecting the privacy of children is especially important. PinakArc does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body2">
          If you have any questions or concerns regarding this Privacy Policy or the practices of our Website, please contact us at{' '}
          <Link href="mailto:info@pinakarc.com">info@pinakarc.com</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
