import React from "react";
import Typography from "@mui/material/Typography";
import cx from 'classnames';
import { Grid, Card, CardContent } from "@mui/material";
import styles from './ValueDeliver.module.scss';
import Services from "../Services/Services";

const ValueDeliver = () => {
  return (
    <div className={styles.container}>
      <Typography className={styles.titleText}>
        How We <Typography className={styles.titleTextHighlight}>&nbsp;Deliver&nbsp;</Typography> Value
      </Typography>

      <div className={cx(styles.valueDeliverContainer)}>
        <Grid container spacing={2} className={cx(styles.valueDeliverGrid)}>
          <Grid item xs={12} sm={6} md={4} key={1} marginBottom={3} >
            <Card sx={{ margin: 1 }} className={cx(styles.valueDeliverCard)}>
              <CardContent>
                <Typography className={styles.cardTitle}>
                  Exclusive Development Squad
                </Typography>
                <Typography className={styles.cardDescription}>
                  We establish your top-tier software development team either locally or internationally and provide continuous support as you collaborate directly with your team, enabling you to concentrate on development and delivery without distraction.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} key={1} marginBottom={3} >
            <Card sx={{ margin: 1 }} className={cx(styles.valueDeliverCard)}>
              <CardContent>
                <Typography className={styles.cardTitle}>
                  Supervised Endeavors
                </Typography>
                <Typography className={styles.cardDescription}>
                  we achieve swift and efficient delivery with our elite software development professionals, a proven delivery approach, and a dependable fully managed service, ensuring exceptional value and satisfaction for our clients every time.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} key={1} marginBottom={3} >
            <Card sx={{ margin: 1 }} className={cx(styles.valueDeliverCard)}>
              <CardContent>
                <Typography className={styles.cardTitle}>
                  Technology Talent Acquisition
                </Typography>
                <Typography className={styles.cardDescription}>
                  We assist you in recruiting the top 1% of rigorously vetted software developers, UX/UI designers, project managers, and various other engineering specialists at a fraction of the typical cost.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} key={1} marginBottom={3} >
            <Card sx={{ margin: 1 }} className={cx(styles.valueDeliverCard)}>
              <CardContent>
                <Typography className={styles.cardTitle}>
                  Marketing for Scalability
                </Typography>
                <Typography className={styles.cardDescription}>
                  We assist you in unleashing your distinctive growth loops and implementing them with frameworks and strategies aimed at accelerating growth and maximizing revenue potential.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} key={1} marginBottom={3} >
            <Card sx={{ margin: 1 }} className={cx(styles.valueDeliverCard)}>
              <CardContent>
                <Typography className={styles.cardTitle}>
                  Product & Platforms
                </Typography>
                <Typography className={styles.cardDescription}>
                  We construct top-tier software products characterized by a clear vision and purpose, a user-centric approach, and a robust, scalable architecture.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} key={1} marginBottom={3} >
            <Card sx={{ margin: 1 }} className={cx(styles.valueDeliverCard)}>
              <CardContent>
                <Typography className={styles.cardTitle}>
                  Startup Venture Studio
                </Typography>
                <Typography className={styles.cardDescription}>
                  We collaborate with companies and entrepreneurs to conceive, validate, develop, and expand innovative ventures into market-leading, venture-funded enterprises with swift customer adoption{" "}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Services />
    </div>
  );
};

export default ValueDeliver;
