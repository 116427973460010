import React, { useState, useEffect } from 'react';
import { Fab, useTheme, Zoom } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const GoTopButton: React.FC = () => {
  const theme = useTheme();
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolling(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    
    // Clean up the scroll event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Zoom in={isScrolling} appear={true}>
      <Fab
        color="primary"
        size="small"
        style={{
          position: 'fixed',
          bottom: theme.spacing(8),
          right: theme.spacing(2),
        }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <KeyboardDoubleArrowUpIcon />
      </Fab>
    </Zoom>
  );
};

export default GoTopButton;
