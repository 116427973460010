import React from 'react';
import { useScroll, animated, useSpring } from '@react-spring/web';
import ValueDeliver from '../../../organisms/ValueDeliver/ValueDeliver';
import Insights from '../../../organisms/Insights/Insights';
import GoToTop from '../../../organisms/GoToTop/GoToTop';
import ContactUs from '../../../organisms/ContactUs/ContactUs';
import styles from './Home.module.scss';
import Hero from '../../../organisms/Hero';
import Partners from '../../../organisms/Partners/Partners';

const Home: React.FC = () => {
    const { scrollYProgress } = useScroll();

    // Define the animation spring
    const animationProps = useSpring({
        from: { opacity: 0, transform: 'translateY(20px) scale(0.5)' },
        to: { opacity: 1, transform: 'translateY(0px) scale(1)' },
        config: { tension: 100, friction: 20 },
    });

    const zoomOutProps = useSpring({
        transform: scrollYProgress.to((scrollY) => `scale(${1 - scrollY * 1})`),
        config: { tension: 100, friction: 20 },
    });

    const zoomOutProps2 = useSpring({
        transform: scrollYProgress.to((scrollY) => `scale(${1 - scrollY * 0.3})`),
        config: { tension: 100, friction: 20 },
    });

    const zoomOutProps3 = useSpring({
        transform: scrollYProgress.to((scrollY) => `scale(${1 - scrollY * 0.2})`),
        config: { tension: 100, friction: 20 },
    });

    return (
        <div className={styles.mainContainer}>
            <section id='' className={styles.heroSection}>
                <animated.div style={{ ...animationProps, ...zoomOutProps }}>
                    <Hero />
                </animated.div>
            </section>
            <section id='services' className={styles.servicesSection}>
                <animated.div style={{ ...animationProps, ...zoomOutProps2 }}>
                    <ValueDeliver />
                </animated.div>
            </section>
            <section id='testimonial' className={styles.servicesSection}>
                <animated.div style={{ ...animationProps, ...zoomOutProps3 }}>
                    <Partners />
                </animated.div>
            </section>
            <section id='insights' className={styles.servicesSection}>
                <animated.div style={{ ...animationProps, ...zoomOutProps3 }}>
                    <Insights />
                </animated.div>
            </section>
            <section id='' className={styles.servicesSection}>
                <animated.div style={{ ...animationProps }}>
                    <ContactUs />
                </animated.div>
            </section>
            <GoToTop />
        </div>
    );
};

export default Home;
