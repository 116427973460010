import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

const TermsAndConditions: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
      <Typography variant="h4" gutterBottom>
        Terms of Services
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Acceptance of the Terms and Conditions</strong>
        <br />
        To use PinakArc's services, you must be legally capable of entering into a binding agreement. By using our services, you agree to abide by these general terms. If you do not agree with any part of these terms, do not proceed to use our services.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Terms of Use</strong>
        <br />
        By accessing and using the PinakArc website, you agree to comply with the terms and conditions outlined in this document. Your continued use of the website constitutes acceptance of these terms. If you do not agree with any part of these terms, do not continue to use the PinakArc website.
        <br />
        <strong>Definitions:</strong> The terms "Client," "You," and "Your" refer to users of this website who have agreed to PinakArc's terms and conditions. "The Company," "We," "Us," and "Our" refer to PinakArc. "Parties" refers collectively to the client and PinakArc.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Copyright Policy</strong>
        <br />
        All content on the PinakArc website is protected under relevant Indian and international copyright laws. The Indian Copyright (Amendment) Act, 1999 governs copyright-related issues in India. Any infringement of copyright will be subject to legal prosecution.
        <br />
        <strong>Usage:</strong> Content from the PinakArc website may be copied for personal use only. Replication, editing, or distribution for public or commercial use is prohibited without prior consent.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Use of Website</strong>
        <br />
        By using the PinakArc website, you agree to adhere to the following terms:
        <ul>
          <li>Do not use any content (text, images, videos, etc.) from this website for public or commercial purposes without authorization.</li>
          <li>Do not engage in spamming using contact details provided on this website.</li>
          <li>Links to third-party websites on PinakArc are used at your own risk. PinakArc is not responsible for the content or privacy policies of these websites.</li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Privacy and Personal Information</strong>
        <br />
        Your use of PinakArc's services requires providing accurate personal information. By using our services, you agree to our Privacy Policy. You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Intellectual Property</strong>
        <br />
        The PinakArc website and its original content are protected by national and international copyright and intellectual property laws. All rights are reserved to PinakArc Infotech.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Disclaimer</strong>
        <br />
        Information on the PinakArc website is provided "as is." PinakArc makes no warranties, expressed or implied, regarding the accuracy or reliability of this information. Use of the website and its content is at your own risk.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Limitation of Liability</strong>
        <br />
        PinakArc shall not be liable for any damages, including loss of data or profits, arising from the use of the website or its content, even if advised of the possibility of such damages.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Indemnification</strong>
        <br />
        You agree to indemnify and hold PinakArc harmless from any claims or demands arising from your use of the website, your violation of these Terms of Use, or your violation of any laws or rights of third parties.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Modifications</strong>
        <br />
        These Terms and Conditions may be revised at any time without prior notice. By continuing to use the PinakArc website, you agree to be bound by the latest version of these terms.
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body2">
          For questions or concerns about these Terms and Conditions, please contact us at{' '}
          <Link href="mailto:info@pinakarc.com">info@pinakarc.com</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
