import React from 'react';
import './App.modules.scss';

import { Routes, Route, useLocation } from "react-router-dom";
import NotFoundPage from './component/pages/NotFoundPage';
import { ThemeProvider } from '@mui/material/styles';
import theme from './resources/theme/theme';
import Home from './component/pages/Home/Home/Home';
import CssBaseline from '@mui/material/CssBaseline';
import TermsAndConditions from './component/pages/TermsAndConditions/TermsAndConditions';
import NavBar from './component/organisms/Navbar';
import Footer from './component/organisms/Footer/Footer';
import PrivacyPolicy from './component/pages/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from './component/pages/CookiePolicy/CookiePolicy';

function App() {
  const location = useLocation();

  // Define the routes where you do not want to show the NavBar and Footer
  const hideNavAndFooter = ['/terms-conditions', '/privacy-policy', '/cookie-policy'];

  const shouldHideNavAndFooter = hideNavAndFooter.includes(location.pathname);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main className={'mainLayout'}>
        {/* {!shouldHideNavAndFooter && <NavBar />} */}
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        {/* {!shouldHideNavAndFooter && <Footer />} */}
        <Footer />
      </main>
    </ThemeProvider>
  );
}

export default App;
