import React from "react";
import { Typography } from "@mui/material";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import styles from './Partners.module.scss';
import Zensar from "../../../resources/images/zensar.svg";
import Oracle from "../../../resources/images/oracle.svg";
import Clark from "../../../resources/images/clark.svg";
import PetBuddy from "../../../resources/images/PetBuddy.png";

interface Image {
    id: number;
    url: string;
    name: string;
}

const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 3,
        slidesToSlide: 1,
        partialVisibilityGutter: 100
    },
    mobile: {
        breakpoint: {
            max: 1024,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 100,
        slidesToSlide: 1,
    },

};

const Partners = () => {
    const images: Image[] = [
        {
            id: 2,
            url: "https://upload.wikimedia.org/wikipedia/commons/0/0f/MX_Player_Logo.png",
            name: 'MX Player'
        },
        {
            id: 3,
            url: Clark,
            name: 'Clark.io'
        },
        {
            id: 4,
            url: Oracle,
            name: 'Oracle'
        },
        {
            id: 5,
            url: "https://asset.brandfetch.io/idF5_nJIr4/idwbfbiUtt.svg?updated=1667771112129",
            name: 'Xebia'
        },
        {
            id: 7,
            url: Zensar,
            name: 'Zensar Technologies'
        }
    ];

    return (
        <div className={styles.partnersContainer}>
            <div className={styles.titleContainer}>
                <Typography className={styles.titleText}>
                    Dive Into Our  {' '} <Typography className={styles.titleTextHighlight}>&nbsp;Work&nbsp;</Typography>
                </Typography>
                <img className={styles.petBanner} width={'100%'} alt='PetBuddy' src={PetBuddy} />
            </div>
            <div className={styles.container}>
                <div>
                    <Typography fontWeight={700} textAlign={'center'} variant='h4' color={'primary'}>
                        Trusted By
                    </Typography>
                    <Carousel
                        additionalTransfrom={0}
                        arrows={false}
                        autoPlay={true}
                        autoPlaySpeed={1500}
                        centerMode={false}
                        className={styles.carasouelContainer}
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable={false}
                        focusOnSelect={false}
                        infinite
                        itemClass={styles.imageContainer}
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={2}
                        swipeable
                    >
                        {images.map(image => (
                            <img key={image.id} className={styles.image} alt={image.name} src={image.url} />
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default Partners;
