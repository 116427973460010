import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

const CookiePolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
      <Typography variant="h4" gutterBottom>
        Cookie Policy
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Introduction</strong>
        <br />
        Our website, https://pinakarc.com (hereinafter: “the website”) uses cookies and other related technologies (collectively referred to as “cookies”). Cookies are also placed by third parties we have engaged. In the document below, we inform you about the use of cookies on our website.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>What are cookies?</strong>
        <br />
        A cookie is a small simple file that is sent along with pages of this website and stored by your browser on the hard drive of your computer or another device. The information stored therein may be returned to our servers or to the servers of the relevant third parties during a subsequent visit.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>What are scripts?</strong>
        <br />
        A script is a piece of program code that is used to make our website function properly and interactively. This code is executed on our server or on your device.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>What is a web beacon?</strong>
        <br />
        A web beacon (or a pixel tag) is a small, invisible piece of text or image on a website that is used to monitor traffic on a website. In order to do this, various data about you is stored using web beacons.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Cookies</strong>
        <br />
        <Typography component="div" variant="body2" sx={{ ml: 2 }}>
          <strong>5.1 Technical or functional cookies</strong>
          <br />
          Some cookies ensure that certain parts of the website work properly and that your user preferences remain known. By placing functional cookies, we make it easier for you to visit our website. This way, you do not need to repeatedly enter the same information when visiting our website and, for example, the items remain in your shopping cart until you have paid. We may place these cookies without your consent.
        </Typography>

        <Typography component="div" variant="body2" sx={{ ml: 2 }}>
          <strong>5.2 Statistics cookies</strong>
          <br />
          We use statistics cookies to optimize the website experience for our users. With these statistics cookies we get insights in the usage of our website. We ask your permission to place statistics cookies.
        </Typography>

        <Typography component="div" variant="body2" sx={{ ml: 2 }}>
          <strong>5.3 Marketing/Tracking cookies</strong>
          <br />
          Marketing/Tracking cookies are cookies or any other form of local storage, used to create user profiles to display advertising or to track the user on this website or across several websites for similar marketing purposes.
        </Typography>

        <Typography component="div" variant="body2" sx={{ ml: 2 }}>
          <strong>5.4 Social media</strong>
          <br />
          On our website, we have included content from Facebook, Twitter, LinkedIn and Instagram to promote web pages (e.g. “like”, “pin”) or share (e.g. “tweet”) on social networks like Facebook, Twitter, LinkedIn and Instagram. This content is embedded with code derived from Facebook, Twitter, LinkedIn and Instagram and places cookies. This content might store and process certain information for personalized advertising.
          <br />
          Please read the privacy statement of these social networks (which can change regularly) to read what they do with your (personal) data which they process using these cookies. The data that is retrieved is anonymized as much as possible. Facebook, Twitter, LinkedIn and Instagram are located in the United States.
        </Typography>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Consent</strong>
        <br />
        When you visit our website for the first time, we will show you a pop-up with an explanation about cookies. As soon as you click on “Save preferences”, you consent to us using the categories of cookies and plug-ins you selected in the pop-up, as described in this Cookie Policy. You can disable the use of cookies via your browser, but please note that our website may no longer work properly.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Enabling/disabling and deleting cookies</strong>
        <br />
        You can use your internet browser to automatically or manually delete cookies. You can also specify that certain cookies may not be placed. Another option is to change the settings of your internet browser so that you receive a message each time a cookie is placed. For more information about these options, please refer to the instructions in the Help section of your browser.
        <br />
        Please note that our website may not work properly if all cookies are disabled. If you do delete the cookies in your browser, they will be placed again after your consent when you visit our website again.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Your rights with respect to personal data</strong>
        <br />
        You have the following rights with respect to your personal data:
        <ul>
          <li>You have the right to know why your personal data is needed, what will happen to it, and how long it will be retained for.</li>
          <li>Right of access: You have the right to access your personal data that is known to us.</li>
          <li>Right to rectification: you have the right to supplement, correct, have deleted or blocked your personal data whenever you wish.</li>
          <li>If you give us your consent to process your data, you have the right to revoke that consent and to have your personal data deleted.</li>
          <li>Right to transfer your data: you have the right to request all your personal data from the controller and transfer it in its entirety to another controller.</li>
          <li>Right to object: you may object to the processing of your data. We comply with this, unless there are justified grounds for processing.</li>
        </ul>
        To exercise these rights, please contact us. Please refer to the contact details at the bottom of this Cookie Policy. If you have a complaint about how we handle your data, we would like to hear from you, but you also have the right to submit a complaint to the supervisory authority (the Data Protection Authority).
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body2">
          If you have any questions or concerns regarding this Cookie Policy or the practices of our Website, please contact us at{' '}
          <Link href="mailto:info@pinakarc.com">info@pinakarc.com</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default CookiePolicy;
