
import { createTheme } from '@mui/material/styles';

export const APP_PRIMARY_COLOR = '#7255F7';
export const APP_BLACK_COLOR = '#000000';
export const APP_WHITE_COLOR = '#FFFFFF';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Optimist'
    ].join(','),
    allVariants: {
      lineHeight: 1.5,
    },
  },
  palette: {
    primary: {
      main: APP_PRIMARY_COLOR,
    },
    secondary: {
      main: APP_BLACK_COLOR,
    },
    text: {
      primary: APP_BLACK_COLOR,
      secondary: APP_PRIMARY_COLOR,
    },

  },

});

export default theme;