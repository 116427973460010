import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box, Dialog, Link, useMediaQuery } from '@mui/material';
import AppIcon from '../../../resources/images/AppIcon.png';
import styles from './Footer.module.scss';
import theme from '../../../resources/theme/theme';
import cx from 'classnames';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TermsAndConditions from '../../pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Footer() {
  const isMobile = useMediaQuery(theme.breakpoints.down(1023));
  const [openTC, setOpenTC] = React.useState(false);

  const handleClickOpenTC = () => {
    setOpenTC(true);
  };

  const handleCloseTC = () => {
    setOpenTC(false);
  };

  const [openPrivacy, setOpenPrivacy] = React.useState(false);

  const handleClickOpenPrivacy = () => {
    setOpenPrivacy(true);
  };

  const handleClosePrivacy = () => {
    setOpenPrivacy(false);
  };
  return (
    <>
      <div className={styles.footer}>
        <Box
          component='footer'
          sx={{
            p: 2,
            pt: 4,
            pb: 4,
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #7255F750',
          }}
        >
          <div className={styles.servicesList} >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem'
            }} >
              <Typography variant="h4">
                <span className={cx(styles.navContainer, styles.navBrn)}>
                  <img alt='PinakArc' src={AppIcon} className={styles.navAppIcon}></img>
                  <span className={styles.navHighlight}>Pinak</span>
                  <span className={styles.navNoHighlight}>Arc</span>
                </span>
              </Typography>
              <div >
                <Link href='https://maps.app.goo.gl/hS8CqFfqXgLCoo586' target='_blank'>
                  <Typography variant='h6' color='common.black' fontWeight={600}>
                    Location
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    821, Square Corporate Park,
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Science City Road, Ahmedabad-380060,
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Gujarat, India
                  </Typography>
                </Link>
              </div>
              <div >
                <Link href='mailto:info@pinakarc.com' target='_blank'>
                  <Typography variant='h6' color='common.black' fontWeight={600}>
                    Email
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    info@pinakarc.com
                  </Typography>
                </Link>
              </div>
              <div >
                <Link href='tel:+919898051474' target='_blank'>
                  <Typography variant='h6' color='common.black' fontWeight={600}>
                    Contact
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    +91 989 805 1474
                  </Typography>
                </Link>
              </div>
            </div>
            <div>
              <div >
                <Link href='#contact'>
                  <Typography variant='h6' color='common.black' fontWeight={600}>
                    For Services
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Mobile App Development
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Front-end Development
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Web Development
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    E Commerce Development
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    UI/UX Design
                  </Typography>
                </Link>
              </div>
            </div>
            <div>
              <div >
                <Link href='#contact'>
                  <Typography variant='h6' color='common.black' fontWeight={600}>
                    For Talent
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Hire Full Stack Developer
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Hire Flutter Developer

                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Hire iPhone Developer
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Hire ReactJs Developer
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Hire AngularJs Developer
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Hire NodeJs Developer
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Hire React Native Developer
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Hire Quality Assurance Engineer
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Hire UI/UX Developer
                  </Typography>
                </Link>
              </div>
            </div>
            <div>
              <div >
                <Link href='#contact'>
                  <Typography variant='h6' color='common.black' fontWeight={600}>
                    For Solutions
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Pet Care
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    School
                  </Typography>
                  <Typography variant='body1' color='common.black'>
                    Chat Assistant
                  </Typography>
                </Link>
              </div>
            </div>

          </div>
        </Box>
      </div>
      <Box pt={2} pb={2} display={'flex'} sx={{
        flexDirection: isMobile ? 'column' : 'row',
        backgroundColor: theme.palette.primary.main,
      }} justifyContent={'space-between'} gap={2}>
        <Grid className={styles.bottomLinkLeft} display={'flex'} gap={2} sx={{
          flexDirection: 'row',
        }}
        >
          <Link underline='hover'>
            <Typography onClick={handleClickOpenTC} variant='caption' color='common.white' align='center' fontWeight={500}>
              Terms of Service
            </Typography>
          </Link>
          <Link underline='hover'>
            <Typography onClick={handleClickOpenPrivacy} variant='caption' color='common.white' align='center' fontWeight={500}>
              Privacy Policy
            </Typography>
          </Link>
          {/* <Link href='/cookie-policy' target="_blank" underline='hover'>
            <Typography variant='caption' color='text.primary' align='center' fontWeight={500}>
              Cookie Policy
            </Typography>
          </Link> */}
        </Grid>
        <Grid className={styles.bottomLinkRight} sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
        }}>
          <Typography variant='caption' color='common.white' align='center' fontWeight={500}>
            {`${new Date().getFullYear()} © `}
            PINAKARC. ALL RIGHTS RESERVED
          </Typography>
        </Grid>
      </Box>
      <Dialog
        // fullScreen
        open={openTC}
        onClose={handleCloseTC}
        TransitionComponent={Transition}
        fullWidth
        maxWidth='lg'
        className={styles.dialog}
      >
        <TermsAndConditions />
      </Dialog>
      <Dialog
        // fullScreen
        open={openPrivacy}
        onClose={handleClosePrivacy}
        TransitionComponent={Transition}
        fullWidth
        maxWidth='lg'
        className={styles.dialog}
      >
        <PrivacyPolicy />
      </Dialog>
    </>
  );
}