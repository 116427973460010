import React from "react";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import { Grid, Container } from "@mui/material";
import styles from "./Insights.module.scss";
import Simple from "../../../resources/images/simple.svg";
import Reliale from "../../../resources/images/reliable.svg";
import Care from "../../../resources/images/care.svg";

const Insights = () => {
    return (

        <div className={cx(styles.insightsContainer)}>
            <Typography
                variant="h3"
                className={styles.title}
            >
                Our Core{" "}
                <Typography
                    variant="h3"
                    className={styles.titleHighlighted}
                >
                    &nbsp;Values&nbsp;
                </Typography>
            </Typography>
            <Container className={cx(styles.insightsContent)}>
                <Grid container className={cx(styles.insightGrid)}>
                    <figure className={cx(styles["insight-img-wrapper"])}>
                        <img alt="" className={cx(styles["insight-img"])} src={Simple} width="128" height="187" />
                    </figure>
                    <div className={cx(styles["valueWrapper"])}>
                        <Typography
                            variant="h5"
                            color="text.primary"
                            sx={{ fontWeight: 600 }}
                        >
                            Simple
                        </Typography>
                        <Typography variant='caption' className={cx(styles["valueCaption"])}>
                            Our goal is to simplify the process of getting insured. We
                            are direct, upfront, fact-based and use relainsightle language. We
                            share our expertise and knowledge at any time.
                        </Typography>
                    </div>
                </Grid>
                <Grid container className={cx(styles.insightGrid)}>
                    <figure className={cx(styles["insight-img-wrapper"])}>
                        <img alt="" className={cx(styles["insight-img"])} src={Reliale} width="200" height="187" />
                    </figure>
                    <div className={cx(styles["valueWrapper"])}>
                        <Typography
                            variant="h5"
                            color="text.primary"
                            sx={{ fontWeight: 600 }}
                        >
                            Reliable
                        </Typography>
                        <Typography variant='caption' className={cx(styles["valueCaption"])}>
                            We deliver quality and keep our promises. We are
                            proactive, get things done, and are bold in finding new ways to
                            improve our product and services. We take ownership and deal
                            professionally with all our stakeholders.
                        </Typography>
                    </div>
                </Grid>
                <Grid container className={cx(styles.insightGrid)}>
                    <figure className={cx(styles["insight-img-wrapper"])}>
                        <img alt="" className={cx(styles["insight-img"])} src={Care} width="200" height="187" />
                    </figure>
                    <div className={cx(styles["valueWrapper"])}>
                        <Typography
                            variant="h5"
                            color="text.primary"
                            sx={{ fontWeight: 600 }}
                        >
                            Care
                        </Typography>
                        <Typography variant='caption' className={cx(styles["valueCaption"])}>
                            We are approachable and friendly and encourage other
                            opinions. We are willing to provide the best possible support to
                            our customers and to each other.
                        </Typography>
                    </div>
                </Grid>
            </Container>
        </div>
    );
};

export default Insights;
