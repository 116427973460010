import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AppIcon from '../../../resources/images/AppIcon.png';
import styles from './Navbar.module.scss';
import cx from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

const NavBar: React.FC = () => {
    const [isSticky, setSticky] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(1023));
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const drawerItems = (
        <List>
            <ListItem
                component={Link}
                to="/#"
                onClick={() => {
                    scrollTop();
                    setDrawerOpen(false)
                }}
                className={cx(styles.navLink, { [styles.active]: location.hash === '' })}
            >
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem
                component="a"
                href="#services"
                onClick={() => setDrawerOpen(false)}
                className={cx(styles.navLink, { [styles.active]: location.hash === '#services' })}
            >
                <ListItemText primary="Services" />
            </ListItem>
            <ListItem
                component="a"
                href="#testimonial"
                onClick={() => setDrawerOpen(false)}
                className={cx(styles.navLink, { [styles.active]: location.hash === '#testimonial' })}
            >
                <ListItemText primary="Reviews" />
            </ListItem>
            <ListItem
                component="a"
                href="#insights"
                onClick={() => setDrawerOpen(false)}
                className={cx(styles.navLink, { [styles.active]: location.hash === '#insights' })}
            >
                <ListItemText primary="Insights" />
            </ListItem>
            <ListItem
                component="a"
                href="#contact"
                onClick={() => setDrawerOpen(false)}
                className={cx(styles.navLink, { [styles.active]: location.hash === '#contact' })}
            >
                <ListItemText primary="LET'S TALK" />
            </ListItem>
        </List>
    );

    return (
        <>
            <AppBar classes={{
                root: 'none', // define this class in your Navbar.module.scss
            }}

                position='sticky' className={cx({
                    [styles.navStyle]: isSticky,
                    [styles.navDefault]: !isSticky,
                })}>
                <Toolbar>
                    {isMobile && (
                        <IconButton size='large' edge="start" color='primary' aria-label="menu" onClick={toggleDrawer(true)}>
                            {drawerOpen ? <CloseIcon style={{ height: 32, width: 32 }} /> : <MenuIcon style={{ height: 32, width: 32 }} />}
                        </IconButton>
                    )}
                    <Typography variant="h4" component={Link} to="/#" onClick={scrollTop}>
                        <span className={cx(styles.navContainer, styles.navBrn)}>
                            <img alt='PinakArc' src={AppIcon} className={styles.navAppIcon}></img>
                            <span className={styles.navHighlight}>Pinak</span>
                            <span className={styles.navNoHighlight}>Arc</span>
                        </span>
                    </Typography>
                    {!isMobile && (
                        <div className={cx('ms-auto')}>
                            <Button className={cx(styles.navLink, { [styles.active]: location.hash === '' })} href="/#" onClick={scrollTop}>Home</Button>
                            <Button className={cx(styles.navLink, { [styles.active]: location.hash === '#services' })} href="#services">Services</Button>
                            <Button className={cx(styles.navLink, { [styles.active]: location.hash === '#testimonial' })} href="#testimonial">Reviews</Button>
                            <Button className={cx(styles.navLink, { [styles.active]: location.hash === '#insights' })} href="#insights">Insights</Button>
                            <Button className={cx(styles.navLink, { [styles.active]: location.hash === '#contact' })} href="#contact">LET'S TALK</Button>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                anchor='right'
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{
                    sx: {
                        backgroundColor: '#E3E3FFE6',
                        backdropFilter: 'blur(7px)',
                        width: '100%',
                        height: '100%',
                        marginTop: '64px',
                    }
                }}
                slotProps={{
                    backdrop: {
                        sx: {
                            backgroundColor: 'transparent',

                        }
                    }
                }}
            >
                <div>
                    {drawerItems}
                </div>
            </Drawer>
        </>
    );
};

export default NavBar;
