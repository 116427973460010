import WebsiteDevelopment from '../../../resources/images/home-website-development.svg';
import ECommerceDevelopment from '../../../resources/images/home-ecommerce-development.png';
import MobileDevelopment from '../../../resources/images/home-mobile-app-development.svg';
import SaasDevelopment from '../../../resources/images/home-saas-development-hm.svg';
import WebAppDevelopment from '../../../resources/images/home-web-application-development.png';

export const servicesData = [
    {
        label: 'Website Development',
        image: WebsiteDevelopment,
        description: `Our dedication lies in crafting bespoke web development solutions that engage and convert your audience. Let's captivate your visitors and amplify your message.`,
    },
    {
        label: 'Mobile App Development',
        image: MobileDevelopment,
        description: `PinakArc empowers businesses to create and expand mobile apps across native and cross-platform environments, driving success in today's digital landscape. Let's collaborate on an app to propel your business forward.`,
    },
    {
        label: 'SaaS Development',
        image: SaasDevelopment,
        description: 'PinakArc provides CRM, ERP, management platforms, cloud applications, and tailored software solutions. Partner with us for dependable SaaS development services and bespoke software solutions.',
    },
    {
        label: 'eCommerce Development',
        image: ECommerceDevelopment,
        description: 'PinakArc empowers retailers, distributors, manufacturers, and wholesalers to enhance customer satisfaction, drive sales growth, and broaden market reach through integrated technologies and eCommerce development services.',
    },
    {
        label: 'Web Application Development',
        image: WebAppDevelopment,
        description: `To meet the specific needs of your business or startup, our agile web application experts specialize in developing custom web apps, platforms, and products. Let's connect to discuss the best solution for you.`,
    },
];
