import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import emailjs from '@emailjs/browser';
import styles from './ContactUs.module.scss';
import theme from '../../../resources/theme/theme';
import ContactUs from '../../../resources/images/contact-us.png'

const CustomTextField = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [validationErrors, setValidationErrors] = useState({
    name: false,
    email: false,
    countryCode: false,
    phoneNumber: false,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = () => {
    const errors = {
      name: name.trim() === '',
      email: !emailRegex.test(email),
      countryCode: countryCode.trim() === '' || !/^\d+$/.test(countryCode),
      phoneNumber: phoneNumber.trim() === '' || !/^\d+$/.test(phoneNumber),
    };

    if (Object.values(errors).some(error => error)) {
      setValidationErrors(errors);
      setSnackbarMessage('Please fill the required details.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      setValidationErrors({
        name: false,
        email: false,
        countryCode: false,
        phoneNumber: false,
      });
      emailjs.send(
        process.env.REACT_APP_SERVICE_ID || '',
        process.env.REACT_APP_TEMPLATE_ID || '',
        {
          to_name: process.env.REACT_APP_TO_NAME || '',
          name: name,
          email: email,
          countryCode: countryCode,
          phoneNumber: phoneNumber,
          message: message,
          from_name: name,
        },
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY || '',
        }
      ).then(() => {
        setName('');
        setEmail('');
        setMessage('');
        setCountryCode('');
        setPhoneNumber('');

        setSnackbarMessage('Email sent successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }).catch(() => {
        setSnackbarMessage('Failed to send email. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.aboutUsContainer}>
        <Typography
          variant="h4"
          color={theme.palette.primary.main}
        >
          Together, let's Create Something Spectacular.
        </Typography>
        <img src={ContactUs} alt='pinakarc-contact' width={'50%'} style={{
          alignSelf: 'center'
        }} />
        <Typography
          variant="h5"
        >
          Stand out from the crowd with PinakArc
        </Typography>
        <Typography
          variant="h6"
        >
          We're here to turn your concepts into reality. Discuss your specific needs with us, and we'll connect you with our experts for custom solutions. They will ensure the best outcomes, tailored to your unique demands.
        </Typography>
      </div>
      <section id='contact' className={styles.contactUsFormContainer}>
        <Typography
          variant="h4"
          sx={{
            color: 'white',
            fontSize: '24px',
          }}
        >
          Contact With Us
        </Typography>
        <Typography
          variant="h1"
          sx={{
            color: 'white',
            fontSize: '24px',
          }}
        >
          Talk to our experts to put your idea into motion.
        </Typography>

        <TextField
          label="Name"
          variant="standard"
          value={name}
          onChange={(event) => setName(event.target?.value)}
          error={validationErrors.name}
          inputProps={{ maxLength: 40 }}
          autoComplete={'off'}
          InputLabelProps={{
            style: {
              color: validationErrors.name ? '#FB5053' : 'white',
            },
          }}
          sx={{
            input: {
              color: 'white',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottomColor: 'white',
            },
            backgroundColor: '#7255f7',
            '& .MuiInput-underline:before': {
              borderBottomColor: 'white',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'white',
            },
          }}
        />

        <TextField
          label="Email"
          variant="standard"
          value={email}
          onChange={(event) => setEmail(event.target?.value)}
          error={validationErrors.email}
          inputProps={{ maxLength: 40 }}
          autoComplete={'off'}
          InputLabelProps={{
            style: {
              color: validationErrors.email ? '#FB5053' : 'white',
            },
          }}
          sx={{
            input: {
              color: 'white',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottomColor: 'white',
            },
            backgroundColor: '#7255f7',
            '& .MuiInput-underline:before': {
              borderBottomColor: 'white',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'white',
            },
          }}
        />

        <div className={styles.countryPhoneContainer}>

          <TextField
            label="Code"
            variant="standard"
            value={countryCode}
            onChange={(event) => setCountryCode(event.target?.value)}
            error={validationErrors.countryCode}
            autoComplete={'off'}
            InputProps={{
              startAdornment: <p style={{ color: 'white', fontSize: '1.5rem', alignSelf: 'center', padding: '4px 0 5px' }}>+</p>
            }}
            InputLabelProps={{
              style: {
                color: validationErrors.countryCode ? '#FB5053' : 'white',
              },
            }}
            inputProps={{
              maxLength: 3,
              inputMode: 'numeric'
            }}
            sx={{
              input: {
                color: 'white',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: 'white',
              },
              backgroundColor: '#7255f7',
              '& .MuiInput-underline:before': {
                borderBottomColor: validationErrors.countryCode ? '#FB5053' : 'white',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'white',
              },
              flex: '1 1 20%',
              marginRight: '16px',
            }}
          />
          <TextField
            label="Phone Number"
            variant="standard"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target?.value)}
            error={validationErrors.phoneNumber}
            autoComplete={'off'}
            inputProps={{
              maxLength: 12,
              inputMode: 'numeric'
            }}
            InputLabelProps={{
              style: {
                color: validationErrors.phoneNumber ? '#FB5053' : 'white',
              },
            }}
            sx={{
              input: {
                color: 'white',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: 'white',
              },
              backgroundColor: '#7255f7',
              '& .MuiInput-underline:before': {
                borderBottomColor: validationErrors.phoneNumber ? '#FB5053' : 'white',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'white',
              },
              flex: '1 1 80%',
            }}
          />
        </div>

        <TextField
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={4}
          value={message}
          onChange={(event) => setMessage(event.target?.value)}
          autoComplete={'off'}
          inputProps={{
            maxLength: 1500,
          }} InputLabelProps={{
            style: {
              color: 'white',
            },
          }}
          sx={{
            input: {
              color: 'white',
            },
            '& .MuiInputBase-inputMultiline': {
              color: 'white',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottomColor: 'white',
            },
            backgroundColor: '#7255f7',
            '& .MuiInput-underline:before': {
              borderBottomColor: 'white',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'white',
            },
          }}
        />

        <Button
          onClick={handleSubmit}
          variant='outlined'
          className={styles.submitButton}
        >
          Submit
        </Button>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </section>
    </div>
  );
};

export default CustomTextField;
