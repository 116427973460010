import React, { useState, useMemo } from 'react';
import cx from 'classnames';
import { Tabs, Tab, Typography, Box, Button, useMediaQuery, Accordion, AccordionSummary } from '@mui/material';
import styles from './Services.module.scss';
import EastIcon from '@mui/icons-material/East';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { servicesData } from './ServiceData';

interface TabPanelProps {
    image: string;
    content: string;
}

const TabPanel = ({ image, content }: TabPanelProps) => {
    return (
        <Box sx={{ p: 3 }} display="flex" gap={4} className={styles.servicesTabContent} >
            <div className={cx(styles['tabImgWrapper'])}>
                <img alt="" className={cx(styles['tabImg'])} src={image} />
            </div>
            <div className={cx(styles['tabTextWrapper'])}>
                <div className={cx(styles['tabTextContent'])}>
                    <Typography className={cx(styles['tabText'])}>{content}</Typography>
                    <Button href="#contact" className={cx(styles['tabTextButton'])} variant="contained" color="primary">
                        Explore
                        <EastIcon />
                    </Button>
                </div>
            </div>
        </Box>
    );
};

const Services = () => {
    const [value, setValue] = useState(0);
    const isMobile = useMediaQuery('(max-width: 768px)');

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const renderedTabs = useMemo(() => (
        servicesData.map((service, index) => (
            <Tab
                key={index}
                className={cx({
                    [styles['tabNotSelected']]: value !== index,
                    [styles['tabSelected']]: value === index,
                })}
                label={<Typography variant="button" fontSize={16} fontWeight={500}>{service.label}</Typography>}
            />
        ))
    ), [value]);

    const renderedTabPanels = useMemo(() => (
        servicesData.map((service, index) => (
            value === index && <TabPanel image={service.image} content={service.description} />
        ))
    ), [value]);

    return (
        <div className={cx(styles.servicesContainer)}>
            {isMobile ? (
                servicesData.map((service, index) => (
                    <Accordion
                        sx={{                            
                            '&::before': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        style={{borderRadius: '12px',
                            borderWidth: 0}}
                        key={index}
                        expanded={value === index}
                        onChange={(e) => handleChange(e, index)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="button" fontSize={16} fontWeight={500}>
                                {service.label}
                            </Typography>
                        </AccordionSummary>
                        <TabPanel image={service.image} content={service.description} />
                    </Accordion>
                ))
            ) : (
                <>
                    <Tabs
                        className={cx(styles.servicesTabList)}
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs"
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                height: '100%',
                                justifyContent: 'space-between',
                            },
                            '& .MuiTabs-indicator': { display: 'none' },
                        }}
                    >
                        {renderedTabs}
                    </Tabs>
                    {renderedTabPanels}
                </>
            )}
        </div>
    );
};

export default Services;
